import React from "react";
import "./Footer.scss";

interface Props {
    a?: number;
}

const Footer: React.FC<Props> = (props) => {
    return (
        <div className="footer">
            {/* <div className="footer-divider">
                <Typography className="middle-title" variant="h1" sx={{ color: (theme) => theme.colorPalette.grey.light }}>
                    Vraag een demo aan
                </Typography>
            </div>

            <Grid className="grid-container" container>
                <Grid className="grid-left" item container xs={12} md={8}>
                    <FooterDemo />
                </Grid>

                <Grid item xs={12} md={4}>
                    B
                </Grid>
            </Grid> */}
        </div>
    );
};

export default Footer;
