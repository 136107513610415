import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

interface Props {
    showButtons: boolean;
    setShowButtons(showButtons: boolean): void;
    margin?: string;
}

const ContentScrollerActivator: React.FC<Props> = ({ showButtons, setShowButtons, margin }) => {
    const contentActivator = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.to(".contentActivatorScroll", {
                scrollTrigger: {
                    trigger: ".contentActivatorScroll",
                    start: "top top",
                    scrub: true,
                    pin: true,
                    toggleActions: "play reverse play reverse",
                },
                onComplete: () => setShowButtons(true),
                onReverseComplete: () => setShowButtons(false),
            });
        }, contentActivator);

        return () => ctx.revert();
    }, [setShowButtons]);

    return (
        <div ref={contentActivator} className="contentActivator" style={{ width: "100vw", margin: margin ? margin : "32px 0px" }}>
            <div className="contentActivatorScroll" />
        </div>
    );
};

export default ContentScrollerActivator;
