import React from "react";
import "./DiagonalContainer.scss";

interface Props {
    children: React.ReactNode;
}

const DiagonalContainer: React.FC<Props> = ({ children }) => {
    return <div className="diagonal-container">{children}</div>;
};

export default DiagonalContainer;
