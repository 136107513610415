import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, ListItem, MenuList, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Schedule, Check, Today } from "@mui/icons-material";
import Example from "../../assets/helperImg.png";
import "./CancelTimeslot.scss";
import { gql, useLazyQuery } from "@apollo/client";
import { GET_CHOSEN_TIMESLOT_DETAILS } from "../../queryDefinitions";
import { MeetingTimeslot } from "../../__generated__/graphql";
import queryString from "query-string";
import moment from "moment";

interface Props {
    a?: number;
}

const CancelTimeslot: React.FC<Props> = (props) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const query = queryString.parse(window.location.search);
    const token = query.token as string;
    /**
     * States
     */
    const [cancel, setCancel] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);
    const [name, setName] = React.useState("");
    const [meetingDetails, setMeetingDetails] = useState<MeetingTimeslot | null>(null);

    /**
     * Fetch details of chosen timeslot
     */
    const [GetMeetingDetails] = useLazyQuery(GET_CHOSEN_TIMESLOT_DETAILS, {
        onCompleted: (data) => {
            if (data.meetingTimeslot) setMeetingDetails(data.meetingTimeslot);
        },
    });

    const renderPage = (children: React.ReactNode) => {
        return (
            <Grid container className="demo-grid">
                <Grid container item xs={12} lg={8} className="grid-left">
                    <Grid className="left-left-panel" item xs={12} lg={6}>
                        <img src={Example} alt="meet" />
                    </Grid>
                    <Grid item xs={12} lg={6} className="left-right-panel">
                        <MenuList className="left-menu">
                            <ListItem>
                                {noMobile && <div className="no-square" />}
                                <Typography variant="h1" className="list-text-header">
                                    Wat kun je verwachten?
                                </Typography>
                            </ListItem>

                            <ListItem>
                                <div className="square">
                                    <Check className="check" />
                                </div>
                                <Typography className="list-text">Een online demonstratie voor de mogelijkheden van jouw team</Typography>
                            </ListItem>
                            <ListItem>
                                <div className="square">
                                    <Check className="check" />
                                </div>
                                <Typography className="list-text">Persoonlijk advies over de inzet van ons platform</Typography>
                            </ListItem>
                            <ListItem>
                                <div className="square">
                                    <Check className="check" />
                                </div>
                                <Typography className="list-text">Gratis voorproefje van jouw gepersonaliseerde omgeving</Typography>
                            </ListItem>
                        </MenuList>
                    </Grid>

                    <Grid item xs={12} className="meeting-grid">
                        {children}
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={4} className="grid-right">
                    <Typography variant="h1" className="header">
                        Er ging iets mis
                    </Typography>

                    <div style={{ flex: 1 }}>
                        <div style={{ marginTop: "16px" }}>
                            <ListItem>
                                <div className="square">
                                    <Today sx={{ color: (theme: any) => theme.colorPalette.grey.main, width: "24px", height: "24px" }} />
                                </div>
                                <Typography className="list-text">Geen tijdslot gevonden</Typography>
                            </ListItem>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    };

    useEffect(() => {
        if (token) {
            GetMeetingDetails({
                variables: {
                    hash: token,
                },
            });
        }
    }, [token, GetMeetingDetails]);

    if (meetingDetails === null) {
        return renderPage(
            <div className="meeting-details">
                <div className="meeting-item">
                    <div className="square">
                        <Schedule sx={{ color: (theme) => theme.colorPalette.grey.light, width: 24, height: 24 }} />
                    </div>
                    <Typography className="list-text">20-30 min.</Typography>
                </div>
                <div className="meeting-item">
                    <div className="square">
                        <img src="https://ssl.gstatic.com/calendar/images/conferenceproviders/logo_meet_2020q4_192px.svg" alt="Google Meet" />
                    </div>
                    <Typography className="list-text">Informatie over Google Meet ontvang je in de bevestigingsmail</Typography>
                </div>
            </div>
        );
    }

    const dayCapitalized =
        moment(meetingDetails?.start).format("dddd DD MMMM").charAt(0).toUpperCase() + moment(meetingDetails?.start).format("dddd DD MMMM").slice(1);

    const handleCancel = () => {
        // Loading state from mutation
        setLoading(true);
        setTimeout(() => {
            // Mutation completed succesfull
            setLoading(false);

            setTimeout(() => {
                // Temporary reset => user will be redirected to homepage so localstate resets automatically
                setCompleted(false);
            }, 2000);

            setCompleted(true);
        }, 2500);
    };
    return renderPage(
        <Grid item xs={12} lg={4} className="grid-right">
            <Typography variant="h1" className="header">
                Afspraak details
            </Typography>
            {!completed ? (
                <div style={{ flex: 1 }}>
                    <div style={{ marginTop: "16px" }}>
                        <ListItem>
                            <div className="square">
                                <Check sx={{ color: (theme: any) => theme.colorPalette.green.main, width: "24px", height: "24px" }} />
                            </div>
                            <Typography className="list-text">Gekozen tijdslot is gereserveerd</Typography>
                        </ListItem>
                        <ListItem>
                            <div className="square">
                                <Today sx={{ color: (theme: any) => theme.colorPalette.grey.light, width: "24px", height: "24px" }} />
                            </div>
                            <Typography className="list-text">{dayCapitalized}</Typography>
                        </ListItem>
                        <ListItem>
                            <div className="square">
                                <Schedule sx={{ color: (theme) => theme.colorPalette.grey.light, width: "24px", height: "24px" }} />
                            </div>
                            <Typography className="list-text">
                                {" "}
                                {`${moment(meetingDetails?.start).format("LT")} - ${moment(meetingDetails?.end).format("LT")}`} (
                                {meetingDetails?.duration} minuten)
                            </Typography>
                        </ListItem>
                    </div>
                </div>
            ) : (
                /**
                 * Show div when cancel mutation is completed
                 */
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                    <ListItem style={{ marginTop: "-16px" }}>
                        <div className="square">
                            <Today sx={{ color: (theme: any) => theme.colorPalette.grey.light, width: "24px", height: "24px" }} />
                        </div>
                        <Typography className="list-text">Afspraak is geannuleerd</Typography>
                    </ListItem>
                </div>
            )}
            {!completed && (
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    {/*
                     * div with emailinput
                     */}
                    <div style={{ width: "70%" }}>
                        {cancel && (
                            <TextField
                                fullWidth
                                id="outlined-controlled"
                                variant="standard"
                                placeholder="Vul je e-mailadres ter bevestiging"
                                value={name}
                                type="email"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setName(event.target.value);
                                }}
                            />
                        )}
                    </div>
                    {/* Button to cancel demo */}
                    <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
                        {loading && <CircularProgress style={{ alignSelf: "center" }} size={14} />}
                        {!cancel ? <Button onClick={() => setCancel(true)}>Annuleren</Button> : <Button onClick={handleCancel}>Bevestigen</Button>}
                    </div>
                </div>
            )}
        </Grid>
    );
};

export default CancelTimeslot;

//? Mutation to cancel sales demo
export const CANCEL_TIMESLOT_RESERVATION = gql`
    mutation cancelSalesMeetingDetailView($timeslot_hash: ID!, $email: String!) {
        cancelSalesMeeting(timeslot_hash: $timeslot_hash, email: $email) {
            hash
        }
    }
`;
