import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_ROOT}/graphql`,
});

/**
 * Add a 'listener' on errors during a graphql query/mutation
 * When the error is related to authentication ==> remove the token
 * since it may be expired or corrupt.
 */
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors && process.env.REACT_APP_TG_ENVIRONMENT !== "production") {
        for (const err of graphQLErrors) {
            if (err.message === "Unauthenticated.") {
                console.log("GRAPHQL ERROR", `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`);
                localStorage.removeItem("access_token");
                window.location.reload();
                return;
            }
            console.log("GRAPHQL ERROR", `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`);
        }
    }
    if (networkError && process.env.REACT_APP_TG_ENVIRONMENT !== "production")
        console.log("GRAPHQL NETWORK ERROR", `[Network error]: ${networkError}`);

    return forward(operation);
});

export const client = new ApolloClient({
    link: errorLink.concat(httpLink),
    cache: new InMemoryCache(),
});
