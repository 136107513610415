import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/swiper.min.css";
import "./SwiperCSS.css";

import Axians from "../assets/images/slider/Axians.svg";
import Centric from "../assets/images/slider/Centric.svg";
import Dusseldorp from "../assets/images/slider/Dusseldorp.svg";
import Facilicom from "../assets/images/slider/Facilicom.svg";
import Remondis from "../assets/images/slider/Remondis.svg";
import Timing from "../assets/images/slider/Timing.svg";
import TotalEnergies from "../assets/images/slider/TotalEnergies.svg";
import Tecline from "../assets/images/slider/tecline.svg";
import Griekspoor from "../assets/images/slider/Griekspoor.png";

import { useMediaQuery, useTheme } from "@mui/material";

interface Props {
    a?: number;
}

const ReferentieCarousel: React.FC<Props> = (props) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <div style={{ width: "100%" }}>
            <Swiper
                style={{ display: "flex", height: "150px" }}
                slidesPerView={noMobile ? 5 : 1}
                spaceBetween={30}
                centeredSlides={false}
                loop={true}
                autoplay={{
                    delay: noMobile ? 1500 : 925,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide className="swiperSlide">
                    <a href="https://www.axians.com/" target="_blank" rel="noreferrer">
                        <img src={Axians} alt="" />
                    </a>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                    <a href="https://www.centric.eu/" target="_blank" rel="noreferrer">
                        <img src={Centric} alt="" />
                    </a>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                    <a href="https://dusseldorp.nu/" target="_blank" rel="noreferrer">
                        <img src={Dusseldorp} alt="" />
                    </a>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                    <a href="https://www.facilicom.nl/" target="_blank" rel="noreferrer">
                        <img src={Facilicom} alt="" />
                    </a>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                    <a href="https://www.remondis.com/" target="_blank" rel="noreferrer">
                        <img src={Remondis} alt="" />
                    </a>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                    <a href="https://www.timing.nl/" target="_blank" rel="noreferrer">
                        <img src={Timing} alt="" />
                    </a>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                    <a href="https://totalenergies.com/" target="_blank" rel="noreferrer">
                        <img src={TotalEnergies} alt="" />
                    </a>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                    <a href="https://www.tecline.nl/" target="_blank" rel="noreferrer">
                        <img src={Tecline} alt="" />
                    </a>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                    <a href="https://www.griekspoor.nl/" target="_blank" rel="noreferrer">
                        <img src={Griekspoor} alt="" />
                    </a>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default ReferentieCarousel;
