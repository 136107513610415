import React from "react";
import { Button, Typography } from "@mui/material";

interface Props {
    a?: number;
}

const ErrorPage: React.FC<Props> = (props) => {
    return (
        <div
            style={{
                height: `calc(100vh - 192px)`,
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <Typography variant="h2">Oops!</Typography>
            <Typography style={{ margin: "48px" }}>Sorry, er ging iets mis</Typography>
            <Button variant="contained" href="/">
                Ververs
            </Button>
        </div>
    );
};

export default ErrorPage;
