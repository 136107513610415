import React from "react";
import Hero from "./Hero";
import ContentScrollerActivator from "../../components/ContentScrollerActivator";
import ContentScroller from "../../components/ContentScroller";
import SectionDivider from "../../components/SectionDivider";
import TestimonialSection from "./TestimonialSection";
import DiagonalContainer from "../../components/DiagonalContainer";
import TextualSwitchGrid from "../../components/TextualSwitchGrid";
import TimeLineSection from "./TimeLineSection";
import DemoPlannerCard from "../../components/demoplannercard/DemoPlannerCard";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLogoSetter } from "../../context_providers/showlogoProvider";

interface Props {
    a?: number;
}

const Root: React.FC<Props> = (props) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const tablet = useMediaQuery(theme.breakpoints.up("lg"));
    const { setShowButtons, setShowLogo, showButtons } = useLogoSetter();

    return (
        <React.Fragment>
            {/*
             * Hero section
             */}
            <Hero setShowLogo={setShowLogo} />

            {/*
             * Whitespace
             * div contains gsap scrolltrigger to change buttons in header
             * Custom margin for devices
             */}
            <ContentScrollerActivator
                showButtons={showButtons}
                setShowButtons={setShowButtons}
                margin={tablet ? "144px 0px" : noMobile ? "48px 0px" : "0"}
            />

            {/*
             * Selling points
             * contains grid with text (left) and pricecard (right)
             */}
            <ContentScroller />

            {/*
             * Whitespace
             * Custom spacer for devices
             */}
            <SectionDivider margin={tablet ? "96px 0px" : noMobile ? "48px 0px" : "0"} />

            {/*
             * Testimonial carousel
             * show company logo's in black/white
             * Stop rotate and show colored logo on hover
             */}
            <TestimonialSection />
            {/*
             * Whitespace
             * Custom spacer for devices
             */}
            <SectionDivider margin={tablet ? "160px 0px" : noMobile ? "64px 0px" : "0"} />

            {/*
             * Why section with Gsap scroller
             * Text with image for each section
             */}
            <DiagonalContainer>
                <TextualSwitchGrid />
            </DiagonalContainer>

            {/*
             * Whitespace
             * Custom spacer for devices
             */}
            <SectionDivider margin={tablet ? "0px 0px" : noMobile ? "128px 0px" : "64px"} />

            {/*
             * Functions
             * Showcase important functions (image + text)
             */}
            {/* <FunctionSection /> */}

            {/*
             * Whitespace
             * Custom spacer for devices
             */}
            {/* <SectionDivider margin="96px 0px" /> */}

            {/*
             * Timeline gsap
             * Horizontal timeline on scroll
             */}
            <TimeLineSection />

            {/*
             * Whitespace
             * Custom spacer for devices
             */}
            {/* <SectionDivider margin="96px 0px" /> */}

            {/*
             * Tender cards
             * Section to show tenderdata
             */}
            {/* <TenderSection /> */}

            {/*
             * Whitespace
             * Custom spacer for devices
             */}
            <SectionDivider margin={tablet ? "64px 0px" : noMobile ? "32px 0px" : "0"} />

            {/*
             * Section were user can book a demo
             * Different sizes for each device
             * - mobile, -tablet, -laptop
             */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                <div style={{ display: "flex", width: tablet ? "70%" : noMobile ? "90%" : "100%" }}>
                    <DemoPlannerCard raised={true} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Root;
