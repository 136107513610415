import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ContentSection from "./ContentSection";
import { TYPE, TimeslotContext } from "../routes/landing_page/salesTimeslot/TimeSlotProvider";
import TimeslotDrawer from "../routes/landing_page/salesTimeslot/TimeslotDrawer";

interface Props {
    children: React.ReactNode;
    showLogo: boolean;
    showButtons: boolean;
}

const MainLayout: React.FC<Props> = ({ children, showLogo, showButtons }) => {
    const [openTimeslotDrawer, setOpenTimeslotDrawer] = useState(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [chosenTimeSlot, setChosenTimeSlot] = useState<string>("");
    const [chosenOrg, setChosenOrg] = useState();
    const [searchterm, setSearchterm] = useState<string>("");
    const [formData, setFormData] = useState<TYPE>({
        givenname: "",
        familyname: "",
        email: "",
        phone: "",
        city: "",
        company: { handelsnaam: "", dossiernummer: "" },
        function: "",
    });

    return (
        <TimeslotContext.Provider
            value={{
                openTimeslotDrawer,
                setOpenTimeslotDrawer,
                formData,
                setFormData,
                searchterm,
                setSearchterm,
                chosenOrg,
                setChosenOrg,
                chosenTimeSlot,
                setChosenTimeSlot,
                selectedDate,
                setSelectedDate,
                activeStep,
                setActiveStep,
            }}
        >
            <div style={{ overflow: "hidden" }}>
                {/* Topbar */}
                <Header showLogo={showLogo} showButtons={showButtons} />
                {/* ContentSection -> grid container with margin to keep track of same styling trough page */}
                <ContentSection>{children}</ContentSection>
                {/* Footer with links */}
                <Footer />
                {/* Drawer that contains timeslots */}
                {openTimeslotDrawer && (
                    <TimeslotDrawer
                        onClickClose={() => {
                            // close drawer
                            setOpenTimeslotDrawer(false);
                            // reset form
                            setFormData({
                                givenname: "",
                                familyname: "",
                                email: "",
                                phone: "",
                                city: "",
                                company: { handelsnaam: "", dossiernummer: "" },
                                function: "",
                            });
                            setChosenTimeSlot("");
                            setSelectedDate("");
                            setActiveStep(0);
                            setChosenOrg(undefined);
                            setSearchterm("");
                        }}
                        open={openTimeslotDrawer}
                    />
                )}
            </div>
        </TimeslotContext.Provider>
    );
};

export default MainLayout;
