import React from "react";
import { Grid, ListItem, MenuList, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Schedule, Check } from "@mui/icons-material";
import Example from "../../assets/helperImg.png";
import "./DemoPlannerDetailView.scss";
import moment from "moment";
import { MeetingTimeslot } from "../../__generated__/graphql";
import Today from "@mui/icons-material/Today";

interface Props {
    meetingDetails: MeetingTimeslot | null;
}

const DemoPlannerDetailView: React.FC<Props> = ({ meetingDetails }) => {
    const theme = useTheme();
    // const disableWeekends = (date: any) => {
    //     return moment(date).day() === 0 || moment(date).day() === 6;
    // };
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    const dayCapitalized =
        moment(meetingDetails?.start).format("dddd DD MMMM").charAt(0).toUpperCase() + moment(meetingDetails?.start).format("dddd DD MMMM").slice(1);

    if (meetingDetails === null) {
        return <div />;
    }
    return (
        <Grid container className="demo-grid">
            <Grid container item xs={12} lg={8} className="grid-left">
                <Grid className="left-left-panel" item xs={12} lg={6}>
                    <img src={Example} alt="meet" />
                </Grid>
                <Grid item xs={12} lg={6} className="left-right-panel">
                    <MenuList className="left-menu">
                        <ListItem>
                            {noMobile && <div className="no-square" />}
                            <Typography variant="h1" className="list-text-header">
                                Wat kun je verwachten?
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <div className="square">
                                <Check className="check" />
                            </div>
                            <Typography className="list-text">Een online demonstratie voor de mogelijkheden van jouw team</Typography>
                        </ListItem>
                        <ListItem>
                            <div className="square">
                                <Check className="check" />
                            </div>
                            <Typography className="list-text">Persoonlijk advies over de inzet van ons platform</Typography>
                        </ListItem>
                        <ListItem>
                            <div className="square">
                                <Check className="check" />
                            </div>
                            <Typography className="list-text">Gratis voorproefje van jouw gepersonaliseerde omgeving</Typography>
                        </ListItem>
                    </MenuList>
                </Grid>

                <Grid item xs={12} className="meeting-grid">
                    <div className="meeting-details">
                        <div className="meeting-item">
                            <div className="square">
                                <Schedule sx={{ color: (theme) => theme.colorPalette.grey.light, width: 24, height: 24 }} />
                            </div>
                            <Typography className="list-text">20-30 min.</Typography>
                        </div>
                        <div className="meeting-item">
                            <div className="square">
                                <img src="https://ssl.gstatic.com/calendar/images/conferenceproviders/logo_meet_2020q4_192px.svg" alt="Google Meet" />
                            </div>
                            <Typography className="list-text">Informatie over Google Meet ontvang je in de bevestigingsmail</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} lg={4} className="grid-right">
                <Typography variant="h1" className="header">
                    Afspraak gepland!
                </Typography>
                <div style={{ marginTop: "16px" }}>
                    <ListItem>
                        <div className="square">
                            <Check sx={{ color: (theme: any) => theme.colorPalette.green.main, width: "24px", height: "24px" }} />
                        </div>
                        <Typography className="list-text">Gekozen tijdslot is gereserveerd</Typography>
                    </ListItem>
                    <ListItem>
                        <div className="square">
                            <Today sx={{ color: (theme: any) => theme.colorPalette.grey.light, width: "24px", height: "24px" }} />
                        </div>
                        <Typography className="list-text">{dayCapitalized}</Typography>
                    </ListItem>
                    <ListItem>
                        <div className="square">
                            <Schedule sx={{ color: (theme) => theme.colorPalette.grey.light, width: "24px", height: "24px" }} />
                        </div>
                        <Typography className="list-text">
                            {" "}
                            {`${moment(meetingDetails?.start).format("LT")} - ${moment(meetingDetails?.end).format("LT")}`} (
                            {meetingDetails?.duration} minuten)
                        </Typography>
                    </ListItem>
                </div>
            </Grid>
        </Grid>
    );
};

export default DemoPlannerDetailView;
