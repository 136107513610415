import { CircularProgress } from "@mui/material";
import React from "react";

interface Props {
    a?: number;
}

const FullPageSpinner: React.FC<Props> = (props) => {
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
            <CircularProgress color="primary" />
        </div>
    );
};

export default FullPageSpinner;
