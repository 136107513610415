import React, { useEffect, useState } from "react";
import { Badge, Box, Button, Grid, Typography } from "@mui/material";
import "./DemoPlannerStepTwo.scss";
import TimeSlots from "../../routes/landing_page/salesTimeslot/TimeSlots";
import { MeetingTimeslot } from "../../__generated__/graphql";
import UserInfoForm from "../../routes/landing_page/salesTimeslot/UserInfoForm";
import { DatePicker, LocalizationProvider, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";
import FullPageSpinner from "../FullPageSpinner";
import { Disable } from "react-disable";
import UserInfoFormNoCheck from "../../routes/timeslotbooker/UserInfoForm";
import { useLocation } from "react-router-dom";

interface Props {
    selectedDate: string;
    setSelectedDate(selectedDate: any): void;
    availableSlots: (MeetingTimeslot | null)[] | null | undefined;
    chosenTimeSlot: string;
    setChosenTimeSlot(chosenTimeSlot: string): void;
    // Userform
    loadingForm: boolean;
    errors: any;
    control: any;
    setChosenOrg(chosenOrg: any): void;
    chosenOrg: any;
    isValid: any;
    onPrevClick(): void;
    searchterm: string;
    setSearchterm(searchterm: string): void;
    timeslotarray: any[] | null | undefined;
}

const DemoPlannerStepTwo: React.FC<Props> = ({
    selectedDate,
    setSelectedDate,
    availableSlots,
    chosenTimeSlot,
    setChosenTimeSlot,
    loadingForm,
    errors,
    control,
    chosenOrg,
    setChosenOrg,
    isValid,
    onPrevClick,
    searchterm,
    setSearchterm,
    timeslotarray,
}) => {
    const [highlightedDays, setHighlightedDays] = useState([] as any);
    const { pathname } = useLocation();

    /**
     * Set timeslotarray to highlighted state
     * highlighted state will be used to generate pink dots to show user on which date timeslots will be available
     */
    useEffect(() => {
        if (timeslotarray) {
            setHighlightedDays(timeslotarray);
        }
    }, [timeslotarray]);

    /**
     * Map over date array to set format to moment()
     */
    const moments = highlightedDays.map((d: any) => moment(d));

    /**
     * Get latest date in array
     * is used to set the max range of clickable dates
     */
    const maxDate = moment.max(moments);

    /**
     * @returns badge component is visible when a timeslot is available on a specific date
     */
    function ServerDay(props: PickersDayProps<any> & { highlightedDays?: string[] }) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
        const isSelected = highlightedDays.includes(moment(props.day).format("YYYY-MM-DD"));

        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={
                    isSelected ? (
                        <Box
                            sx={{
                                width: "8px",
                                height: "8px",
                                borderRadius: "4px",
                                marginRight: "4px",
                                marginTop: "4px",
                                backgroundColor: (theme) => theme.colorPalette.pink.main,
                            }}
                        />
                    ) : undefined
                }
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    const renderPage = (content: React.ReactNode) => {
        if (pathname.includes("/demo-request/")) {
            return (
                <Grid container className="demo-grid-form">
                    <Grid item xs={12} lg={5} className="timeslots">
                        {content}
                    </Grid>
                    <Grid item xs={12} lg={7} className="user-form">
                        <Disable disabled={chosenTimeSlot === ""}>
                            <UserInfoFormNoCheck
                                loading={loadingForm}
                                errors={errors}
                                control={control}
                                setChosenOrg={setChosenOrg}
                                chosenTimeSlot={chosenTimeSlot}
                                setSearchterm={setSearchterm}
                                searchterm={searchterm}
                                // chosenOrg={chosenOrg}
                            />
                        </Disable>

                        <div className="user-form-send-btn">
                            <Button
                                disabled={
                                    _.isEmpty(errors) === false || !isValid || chosenTimeSlot === "" || (searchterm === "" && chosenOrg === undefined)
                                }
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Bevestig tijdstip
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid container className="demo-grid-form">
                <Grid item xs={12} lg={5} className="timeslots">
                    {content}
                </Grid>
                <Grid item xs={12} lg={7} className="user-form">
                    <Disable disabled={chosenTimeSlot === ""}>
                        <UserInfoForm
                            loading={loadingForm}
                            errors={errors}
                            control={control}
                            setChosenOrg={setChosenOrg}
                            chosenTimeSlot={chosenTimeSlot}
                            setSearchterm={setSearchterm}
                            searchterm={searchterm}
                            // chosenOrg={chosenOrg}
                        />
                    </Disable>

                    <div className="user-form-send-btn">
                        <Button onClick={() => onPrevClick()}>terug</Button>
                        <Button
                            disabled={
                                _.isEmpty(errors) === false || !isValid || chosenTimeSlot === "" || (searchterm === "" && chosenOrg === undefined)
                            }
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Bevestig tijdstip
                        </Button>
                    </div>
                </Grid>
            </Grid>
        );
    };

    if (loadingForm) {
        return renderPage(<FullPageSpinner />);
    }

    if (availableSlots === null || availableSlots === undefined || availableSlots.length === 0) {
        return renderPage(
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <Typography variant="h1">Selecteer het gewenste tijdstip</Typography>
                <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Typography variant="h5">Geen data beschikbaar</Typography>
                </div>
                <div>
                    <Typography style={{ marginRight: "8px" }}>Of kies een datum: </Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker minDate={moment()} format="DD-MM-YYYY" views={["day"]} value={moment(selectedDate)} onChange={setSelectedDate} />
                    </LocalizationProvider>
                </div>
            </div>
        );
    }

    return renderPage(
        <div className="content">
            {availableSlots && !loadingForm && (
                <TimeSlots chosenTimeSlot={chosenTimeSlot} setChosenTimeSlot={setChosenTimeSlot} availableSlots={availableSlots} />
            )}
            <div className="ftrdiv">
                <Typography style={{ marginRight: "8px" }}>Of kies een datum: </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        // minimum clickable date is today
                        minDate={moment()}
                        //  max. clickable date is last date in date array
                        maxDate={maxDate}
                        // override day component
                        slots={{
                            day: ServerDay,
                        }}
                        showDaysOutsideCurrentMonth
                        format="DD-MM-YYYY"
                        views={["day"]}
                        value={moment(selectedDate)}
                        onChange={setSelectedDate}
                        /**
                         * Changes in GUI
                         * - Hide toolbar
                         * - Hide buttons in action bar
                         * - Push highlighted days array to dat component (array used in ServerDay)
                         */
                        slotProps={{
                            day: {
                                highlightedDays,
                            } as any,
                        }}
                    />
                </LocalizationProvider>
            </div>
        </div>
    );
};

export default DemoPlannerStepTwo;
