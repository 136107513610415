import React, { useLayoutEffect, useRef } from "react";
import "./ContentScroller.scss";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PricingCard from "./PricingCard";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

interface Props {
    a?: number;
}

const ContentScroller: React.FC<Props> = (props) => {
    const main = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const boxes = self.selector!(".box");

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const listItems = self.selector!(".pricebox .MuiPaper-root .MuiList-root .MuiListItem-root");
            listItems.forEach((li: any) => {
                gsap.to(li, {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: li,
                        start: "bottom 85%",
                        toggleActions: "play none play none",
                    },
                });
            });
            boxes.forEach((box: any) => {
                gsap.to(box, {
                    x: 0,
                    opacity: 1,
                    scrollTrigger: {
                        // markers: true,
                        trigger: box,
                        start: noMobile ? "bottom 70%" : "top 80%",
                        toggleActions: "play none play none",
                    },
                });
            });
        }, main); // <- Scope!
        return () => ctx.revert(); // <- Cleanup!
    }, [noMobile]);

    return (
        <Grid ref={main} container className="contentScroller">
            <Grid item md={6} className="boxContainer">
                <Typography variant="h1" className="h1">
                    Voor <span className="boldHeader">iedereen</span> die zaken doet met <span className="boldHeader">overheden</span>
                </Typography>
                <div className="box">
                    <div className="boxContent">
                        <Typography variant="h3" className="h3">
                            <span className="boldHeader">Inschrijvers</span> van klein tot groot
                        </Typography>
                        <Typography className="boxTextual">
                            Slimmer nieuwe opdrachten vinden, winnen en behouden met één platform. <br /> Werk samen en gebruik onze toolbox binnen
                            jouw groeistrategie. <br />
                            Geen team te klein of groot.
                        </Typography>
                    </div>
                </div>
                <div className="box">
                    <div className="boxContent">
                        <Typography variant="h3" className="h3">
                            <span className="boldHeader">Toeleveranciers </span> en <span className="boldHeader">fabrikanten </span>
                        </Typography>
                        <Typography className="boxTextual">
                            Vind jouw product/dienst op het diepste niveau om meer focus aan te brengen in jullie sales activiteiten. <br />
                            Besteed meer tijd aan je netwerk en minder tijd aan administratie.
                        </Typography>
                    </div>
                </div>
                <div className="box">
                    <div className="boxContent">
                        <Typography variant="h3" className="h3">
                            <span className="boldHeader">Marketeers</span> en <span className="boldHeader">strategen</span>
                        </Typography>
                        <Typography className="boxTextual">
                            Analyseer jouw markten en concurrenten. <br />
                            Bekijk de activiteiten van belangrijke spelers en signaleer nieuwe trends. <br /> Neem betere beslissingen op basis van
                            verrijkte informatie.
                        </Typography>
                    </div>
                </div>
            </Grid>
            <Grid item xs={undefined} md={1} />
            <Grid className="pricebox-container" item xs={12} md={4}>
                <div className="pricebox">
                    <PricingCard id="card" />
                </div>
            </Grid>
        </Grid>
    );
};

export default ContentScroller;
