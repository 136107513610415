import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import LoginIcon from "@mui/icons-material/Login";
import { ReactComponent as LogoTG } from "../assets/tenderguide_logo.svg";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useMediaQuery, useTheme } from "@mui/material";
import HeaderItems from "./HeaderItems";
import { useNavigate } from "react-router-dom";

interface Props {
    showLogo: boolean;
    showButtons: boolean;
}

const ResponsiveAppBar: React.FC<Props> = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const minimalLaptop = useMediaQuery(theme.breakpoints.up("lg"));

    // check if scroll is active to add elevation to bottom of header
    const trigger = useScrollTrigger({ disableHysteresis: true, target: window ? window : undefined });

    const renderHeader = (content: React.ReactNode) => {
        return (
            <AppBar elevation={trigger ? 4 : 0} sx={{ backgroundColor: (theme) => theme.colorPalette.white.main }}>
                {content}
            </AppBar>
        );
    };

    const showTopbar = () => {
        /**
         * Switch contains all states a tender could have, for each state a different component.
         */
        switch (minimalLaptop) {
            case true:
                return renderHeader(<HeaderItems showButtons={props.showButtons} showLogo={props.showLogo} />);

            case false:
                return renderHeader(
                    <Toolbar disableGutters>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <LogoTG width="200" height="200" style={{ height: "60px" }} onClick={() => navigate("/")} />

                            <IconButton style={{ marginRight: "16px" }} href="https://tool.tender.guide">
                                <LoginIcon fontSize="small" />
                            </IconButton>
                        </div>
                    </Toolbar>
                );

            default:
                return null;
        }
    };

    return <React.Fragment>{showTopbar()}</React.Fragment>;
};
export default ResponsiveAppBar;
