import axios from "axios";
export interface Query {
    query: string;
    city: string;
}
export type SearchFunction<T = any> = (query: Query, ...args: any[]) => Promise<T[]>;

const keyKVK: string = process.env.REACT_APP_KVK_API_KEY || "";
const kvkURL: string = process.env.REACT_APP_KVK_URL || "";

const kvkSearch: SearchFunction = async (query: Query, allPages?: boolean, startAt?: number): Promise<unknown[]> => {
    // const response = await axios.get(
    //     `https://zoeken.kvk.nl/JsonSearch.ashx?q=${query.query} ${query.city}${startAt !== undefined ? `&start=${startAt}` : ""}`
    // );

    const response = await axios.get(kvkURL, {
        params: { naam: query.query },
        headers: {
            apikey: keyKVK, // Adding API key to headers
        },
    });
    const entries = response.data.entries;
    console.log("🚀 ~ constkvkSearch:SearchFunction= ~ entries:", entries);

    return entries;

    // const resultsPerPage = parseInt(response.data.resultatenPerPagina, 10);
    // const totalAmountResults = parseInt(response.data.totaal, 10);

    // if (allPages && startAt !== undefined && startAt + resultsPerPage < totalAmountResults) {
    //     return entries.concat(await kvkSearch(query, allPages, startAt + resultsPerPage));
    // } else {
    //     return entries;
    // }
};

export default kvkSearch;
