import React from "react";
import CancelTimeslot from "./CancelTimeslot";
import { Card, Typography, useMediaQuery, useTheme } from "@mui/material";

interface Props {
    a?: number;
}

const TimeSlotDetails: React.FC<Props> = (props) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const tablet = useMediaQuery(theme.breakpoints.up("lg"));

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: `calc(100vh - 192px)` }}>
            <div style={{ display: "flex", width: tablet ? "70%" : noMobile ? "90%" : "100%" }}>
                <div className="demo-card-container">
                    <Typography className="header-title" id="demo-request">
                        Bekijk <span className="boldHeader">afspraak</span>
                    </Typography>

                    <Card className="demo-card" raised={true}>
                        <CancelTimeslot />
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default TimeSlotDetails;
