import { createTheme, responsiveFontSizes } from "@mui/material/styles";
declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xs: true; // removes the `xs` breakpoint
        sm: true;
        md: true;
        lg: true;
        xl: true;
        xxl: true;
    }
}
declare module "@mui/material/styles" {
    interface Theme {
        moduleColors: {
            blue: string;
            pink: string;
            green: string;
            yellow: string;
            grey: string;
        };
        colorPalette: {
            blue: PaletteColor;
            yellow: PaletteColor;
            green: PaletteColor;
            pink: PaletteColor;
            white: PaletteColor;
            black: PaletteColor;
            background: PaletteColor;
            grey: PaletteColor;
            pastel: PaletteColor;
        };
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        moduleColors?: {
            blue: string;
            pink: string;
            green: string;
            yellow: string;
            grey: string;
        };
        colorPalette?: {
            blue: PaletteColor;
            yellow: PaletteColor;
            green: PaletteColor;
            pink: PaletteColor;
            white: PaletteColor;
            background: PaletteColor;
            black: PaletteColor;
            grey: PaletteColor;
            pastel: PaletteColor;
        };
    }
    interface PaletteColor {
        light: string;
        main: string;
        dark: string;
    }
}
/**
 * Customize the default theme of material-ui
 *
 * Read more: https://material-ui.com/customization/theming/
 */
export const materialTheme = responsiveFontSizes(
    createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
                xxl: 2500,
            },
        },
        palette: {
            primary: { main: "#173357", light: "#315b7e" },
            secondary: { main: "#e03660" },
            error: { main: "#c0392b" },
        },
        colorPalette: {
            blue: {
                main: "#173357",
                light: "#465c84",
                dark: "#000b2e",
                contrastText: "#fff",
            },
            yellow: {
                main: "#F57117",
                light: "#ffa24b",
                dark: "#bb4100",
                contrastText: "#fff",
            },
            green: {
                main: "#225E4D",
                light: "#508c79",
                dark: "#003425",
                contrastText: "#fff",
            },
            pink: {
                main: "#E03660",
                light: "#ff6d8d",
                dark: "#a80037",
                contrastText: "#fff",
            },
            white: {
                main: "#ffffff",
                light: "#f4f4f4",
                dark: "#b8b8b8",
                contrastText: "#000",
            },
            black: {
                main: "#000000",
                light: "#818181",
                dark: "#000000",
                contrastText: "#fff",
            },
            background: {
                main: "#ffffff",
                light: "#f4f4f4",
                dark: "#cccccc",
                contrastText: "#000",
            },
            grey: {
                main: "#707070",
                light: "#9e9e9e",
                dark: "#454545",
                contrastText: "#fff",
            },
            pastel: {
                main: "#5f4aea",
                light: "#9e9e9e",
                dark: "#454545",
                contrastText: "#fff",
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: "Maven Pro Regular, system-ui, sans-serif, Material Icons",
            h1: {
                fontSize: "1.8rem",
                "@media (max-width: 800px)": {
                    fontSize: "1.4rem",
                },
            },
            h2: {
                fontSize: "1.6rem",
                "@media (max-width: 800px)": {
                    fontSize: "1.2rem",
                },
            },
            h3: {
                fontSize: "1.4rem",
                "@media (max-width: 800px)": {
                    fontSize: "1.0rem",
                },
            },
            h4: {
                fontSize: "0.8rem",
                spacing: "0.06em",
                "@media (max-width: 800px)": {
                    fontSize: "0.7rem",
                },
            },
            h5: {
                fontSize: "1.2rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.9rem",
                },
            },
            h6: {
                fontSize: "0.95rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
            body1: {
                fontSize: "0.875rem",
                "@media (max-width: 800px)": {
                    fontSize: "0.8rem",
                },
            },
        },
        moduleColors: {
            blue: "#173357",
            pink: "#E03660",
            green: "#225E4D",
            yellow: "#F57117",
            grey: "#707070",
        },
    })
);
