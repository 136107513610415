import React from "react";
import { MeetingTimeslot } from "../../../__generated__/graphql";
import { Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import "./TimeSlots.scss";

interface Props {
    chosenTimeSlot: string;
    setChosenTimeSlot(str: string): void;
    availableSlots: (MeetingTimeslot | null)[];
}

const TimeSlots: React.FC<Props> = ({ chosenTimeSlot, availableSlots, setChosenTimeSlot }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const noLaptop = useMediaQuery(theme.breakpoints.down("lg"));

    // this gives an object with dates as keys
    const groups = availableSlots.reduce((groups: any, time) => {
        const date = moment(time?.start).format("YYYY-MM-DD");
        if (!groups[date] as any) {
            groups[date] = [];
        }
        groups[date].push(time);
        return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
        return {
            date,
            times: groups[date],
        };
    });

    /**
     * TODO: timezone selector (CEST etc.)
     */

    return (
        <React.Fragment>
            <div>
                <Typography variant="h1" className="h3">
                    Selecteer het gewenste tijdstip
                </Typography>
                {groupArrays.map((group) => {
                    const day = moment(group.date).format("dddd D MMMM");
                    const dayCapitalized = day.charAt(0).toUpperCase() + day.slice(1);
                    return (
                        <React.Fragment key={group.date}>
                            <Typography className="dayStamp">{dayCapitalized}</Typography>

                            <Grid xs={10} container style={{ marginBottom: "10px" }}>
                                {/* <div className={classes.daybox}> */}
                                {group.times.map((time: any) => {
                                    return (
                                        <Grid item key={time.hash} lg={4}>
                                            <Button
                                                style={{
                                                    marginBottom: noMobile ? 0 : "4px",
                                                    marginRight: noLaptop && noMobile ? "12px" : 0,
                                                    backgroundColor: time.hash === chosenTimeSlot ? "#173357F2" : "transparent",
                                                    color: time.hash === chosenTimeSlot ? "#fff" : "#173357F2",
                                                }}
                                                variant={"outlined"}
                                                onClick={() => {
                                                    if (chosenTimeSlot !== time.hash) {
                                                        setChosenTimeSlot(time.hash);
                                                    } else {
                                                        setChosenTimeSlot("");
                                                    }
                                                }}
                                            >
                                                {`${moment(time.start).format("LT")} - ${moment(time.end).format("LT")}`}
                                            </Button>
                                        </Grid>
                                    );
                                })}
                                {/* </div> */}
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default TimeSlots;
