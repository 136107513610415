import React, { useLayoutEffect, useRef } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./TextualSwitchGrid.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HumanAndMachine from "../assets/humandAndMachine.svg";
import Contract from "../assets/contract.svg";
import Search from "../assets/searchhighlight.svg";
import Export from "../assets/export_2.svg";
import Notification from "../assets/notification.svg";
import Budget from "../assets/budget.svg";

gsap.registerPlugin(ScrollTrigger);

interface Props {
    a?: number;
}

const TextualSwitchGrid: React.FC<Props> = (props) => {
    const main = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const boxes = self.selector!(".section");
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const contentboxes = self.selector!(".text-section");
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const images = self.selector!(".panel");

            boxes.forEach((box: any) => {
                gsap.to(box, {
                    x: 0,
                    opacity: 1,

                    scrollTrigger: {
                        trigger: box,
                        start: "bottom bottom",
                        toggleActions: "play none none none",
                    },
                });
            });
            contentboxes.forEach((text: any) => {
                gsap.to(text, {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    scrollTrigger: {
                        trigger: text,
                        start: "bottom bottom",
                        toggleActions: "play none none none",
                    },
                });
            });
            images.forEach((text: any) => {
                gsap.to(text, {
                    opacity: 1,
                    duration: 0.7,
                    scrollTrigger: {
                        trigger: text,
                        start: "bottom bottom",
                        toggleActions: "play none none none",
                    },
                });
            });
        }, main); // <- Scope!

        return () => ctx.revert(); // <- Cleanup!
    }, []);

    return (
        <div ref={main}>
            <Typography className="header-title">
                Ontdek hoe <span className="boldHeader">TenderGuide</span> jouw praktijk verandert
            </Typography>

            {data.map((section, i) => {
                return (
                    <Grid
                        key={i}
                        item
                        container
                        direction={noMobile ? (i % 2 === 0 ? "row" : "row-reverse") : "column-reverse"}
                        className="textual-switch-grid"
                    >
                        <Grid item xs={undefined} sm={1} md={1} lg={1} />
                        <Grid item className="section left-section" xs={undefined} sm={5} md={5} lg={4}>
                            <section
                                className="panel"
                                style={{
                                    borderRadius:
                                        i === 1
                                            ? "24% 76% 18% 82% / 79% 26% 74% 21%"
                                            : i === 2
                                            ? "33% 67% 45% 55% / 72% 44% 56% 28%"
                                            : i === 3
                                            ? "29% 62% 8% 81% / 62% 35% 84% 32%"
                                            : i === 4
                                            ? "66% 38% 77% 23% / 43% 81% 26% 70%"
                                            : i === 5
                                            ? "45% 81% 69% 63% / 44% 86% 41% 88%"
                                            : "40% 60% 42% 58% / 45% 38% 62% 55%",
                                }}
                            >
                                <iframe className="image" src={section.image} title="test" />
                            </section>
                        </Grid>
                        <Grid item className="section right-section" xs={12} sm={5} md={5} lg={4}>
                            <section className="text-section">
                                <div style={{ marginBottom: "16px", margin: "32px 32px 16px 32px" }}>
                                    <Typography
                                        variant="h3"
                                        className="content-title"
                                        dangerouslySetInnerHTML={{
                                            __html: section.title,
                                        }}
                                    />

                                    <Typography
                                        className="content-text"
                                        dangerouslySetInnerHTML={{
                                            __html: section.content,
                                        }}
                                    />
                                </div>
                            </section>
                        </Grid>
                        <Grid item xs={undefined} sm={1} md={1} lg={1} />
                    </Grid>
                );
            })}
        </div>
    );
};

export default TextualSwitchGrid;

const data = [
    {
        title: `Het beste van <span class="boldHeader">mens</span> en <span class="boldHeader">machine</span> ineen`,
        content: `<span class="boldHeader">Handmatige verrijking </span> gecombineerd met intuïtieve interfaces en de <span class="boldHeader">krachtigste technieken</span>. De belangrijkste
    gegevens in <span class="boldHeader">één oogopslag</span> voor een snellere go/no-go.`,
        image: HumanAndMachine,
    },
    {
        title: `<span class="boldHeader">Vind wat ertoe doet</span>`,
        content: `Ervaar een <span class="boldHeader">ongekende zoekkracht</span>. Stop met overal zoeken en <span class="boldHeader">vind alles op één plek</span>. 
        Op de manier waarop jij dat wilt. 
        Eenvoudig of complex.`,
        image: Search,
    },
    {
        title: `<span class="boldHeader">Volg en anticipeer</span>`,
        content: `Krijg <span class="boldHeader">updates</span> over aanbestedingen, marktpartijen en aanbestedende diensten die jou interesseren.
        <br /> Bekijk <span class="boldHeader">verrijkte organisatieprofielen</span> en volg specifieke spelers of anticipeer op gebeurtenissen in jouw sector.`,
        image: Notification,
    },
    {
        title: `<span class="boldHeader">Plan meerdere jaren vooruit</span>`,
        content: `Kijk terug in de tijd binnen jouw <span class="boldHeader">persoonlijke bibliotheek</span>.
        <br /> Naar het voorgaande contract. Of dat daarvoor. <br />
        Wees tijdig voorbereid en hou meer tijd over voor beïnvloeding.`,
        image: Contract,
    },
    {
        title: `<span class="boldHeader">Signaleer kansen</span>`,
        content: `Timing is essentieel. 
        <span class="boldHeader">Herken</span> vroegtijdig toekomstige <span class="boldHeader">investeringen</span> en maak jouw <span class="boldHeader">eigen overzichten</span>. <br />
        Breng meer focus aan in het beïnvloeden van overheden.`,
        image: Budget,
    },
    {
        title: `<span class="boldHeader">Overzichten en exports</span>`,
        content: `Stel <span class="boldHeader">eigen overzichten</span> samen of <span class="boldHeader">exporteer resultaten</span> met aanbestedingen uit ons archief van <span class="boldHeader">meer dan 10 jaar</span>.`,
        image: Export,
    },
];
