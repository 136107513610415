import React from "react";
import { Dialog, DialogContent, Zoom, useMediaQuery, useTheme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import DemoPlannerCard from "../../../components/demoplannercard/DemoPlannerCard";

interface Props {
    onClickClose(): void;
    open: boolean;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Zoom ref={ref} {...props} />;
});

const TimeslotDrawer: React.FC<Props> = ({ onClickClose, open }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            onClose={onClickClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "1400px", // Set your width here
                    },
                },
            }}
        >
            <DialogContent style={{ padding: noMobile ? undefined : 0 }}>
                <DemoPlannerCard raised={false} />
            </DialogContent>
        </Dialog>
    );
};

export default TimeslotDrawer;
