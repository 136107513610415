import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import warnSelfXSS from "./utils/warnSelfXSS";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if (process.env.REACT_APP_TG_ENVIRONMENT !== "local") {
    warnSelfXSS(); // Places a warning in the user's browser to not use the JS console
}
// disable console.log in production mode
// eslint-disable-next-line @typescript-eslint/no-empty-function
if (process.env.NODE_ENV !== "development") console.log = () => {};
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
