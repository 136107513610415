import React from "react";
import { Grid } from "@mui/material";

interface Props {
    children: React.ReactNode;
}

const ContentSection: React.FC<Props> = ({ children }) => {
    return (
        <Grid container sx={{ padding: "64px 0px" }}>
            {children}
        </Grid>
    );
};

export default ContentSection;
