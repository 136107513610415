import React from "react";
import { Box } from "@mui/material";

import HorizontalTimeline from "./HorizontalTimeline";

interface Props {
    a?: number;
}

/**
 * @returns Section contains timeline.
 * Section has pseudo-element to show "Timeline"
 */
const TimeLineSection: React.FC<Props> = (props) => {
    return (
        <Box>
            <HorizontalTimeline />
        </Box>
    );
};

export default TimeLineSection;
