import { createContext, useContext } from "react";

export type TimeslotContextType = {
    openTimeslotDrawer: boolean;
    setOpenTimeslotDrawer: (openTimeslotDrawer: boolean) => void;
    formData: TYPE;
    setFormData: (formData: TYPE) => void;
    searchterm: string;
    setSearchterm: (searchterm: string) => void;
    chosenOrg: any;
    setChosenOrg: (chosenOrg: any) => void;
    chosenTimeSlot: string;
    setChosenTimeSlot: (chosenTimeSlot: string) => void;
    selectedDate: string;
    setSelectedDate: (selectedDate: string) => void;
    activeStep: number;
    setActiveStep: (any: any) => void;
};
export interface TYPE {
    givenname: string;
    familyname: string;
    email: string;
    phone: string;
    city: string;
    company: Organization;
    function: string;
}
interface Organization {
    handelsnaam: string;
    dossiernummer: string;
}

export const TimeslotContext = createContext<TimeslotContextType>({
    openTimeslotDrawer: false,
    setOpenTimeslotDrawer: (openTimeslotDrawer) => console.warn(""),
    formData: {
        givenname: "",
        familyname: "",
        email: "",
        phone: "",
        city: "",
        company: { handelsnaam: "", dossiernummer: "" },
        function: "",
    },
    setFormData: (formData: TYPE) => console.warn(""),
    searchterm: "",
    setSearchterm: (searchterm: string) => console.warn(""),
    chosenOrg: undefined,
    setChosenOrg: (chosenOrg: string) => console.warn(""),
    chosenTimeSlot: "",
    setChosenTimeSlot: (chosenTimeSlot: string) => console.warn(""),
    selectedDate: "",
    setSelectedDate: (selectedDate: string) => console.warn(""),
    activeStep: 0,
    setActiveStep: (activeStep: number) => console.warn(""),
});
export const useTimeslotDrawer = () => useContext(TimeslotContext);
