import React, { useEffect, useState } from "react";
import { Card, Typography } from "@mui/material";

import "./DemoPlannerCard.scss";
import moment from "moment";
import DemoPlannerStepOne from "./DemoPlannerStepOne";
import DemoPlannerStepTwo from "./DemoPlannerStepTwo";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
    GetSalesTimeSlotsQuery,
    GetSalesTimeSlotsQueryVariables,
    MeetingTimeslot,
    MeetingTimeslotDaysQuery,
    Save_Chosen_TimeslotMutation,
    Save_Chosen_TimeslotMutationVariables,
} from "../../__generated__/graphql";
import { SubmitHandler, useForm } from "react-hook-form";
import { GET_CHOSEN_TIMESLOT_DETAILS, QUERY_TIMESLOTS, SAVE_CHOSEN_TIMESLOT } from "../../queryDefinitions";
import { useTimeslotDrawer } from "../../routes/landing_page/salesTimeslot/TimeSlotProvider";
import DemoPlannerDetailView from "./DemoPlannerDetailView";
import { isPhoneNumberAllowed, removeSpacesBeforeAfter } from "../../routes/timeslotbooker/BookingRoot";

interface Props {
    raised: boolean;
}
// TODO: save timeslot
export interface TYPE {
    givenname: string;
    familyname: string;
    email: string;
    phone: string;
    city: string;
    company: Organization;
    function: string;
}
interface Organization {
    handelsnaam: string;
    dossiernummer: string;
}

const DemoPlannerCard: React.FC<Props> = ({ raised }) => {
    const {
        formData,
        setFormData,
        searchterm,
        setSearchterm,
        chosenOrg,
        setChosenOrg,
        chosenTimeSlot,
        setChosenTimeSlot,
        selectedDate,
        setSelectedDate,
        activeStep,
        setActiveStep,
    } = useTimeslotDrawer();
    const [meetingDetails, setMeetingDetails] = useState<MeetingTimeslot | null>(null);

    const timeslotarray = useQuery<MeetingTimeslotDaysQuery>(QUERY_TIMESLOTS_DATES);

    /**
     * Book timeslot
     */
    const [book, { error: errorMutation, loading: loadingMutation }] = useMutation<
        Save_Chosen_TimeslotMutation,
        Save_Chosen_TimeslotMutationVariables
    >(SAVE_CHOSEN_TIMESLOT);

    const {
        handleSubmit: submit,
        formState: { errors, isValid },
        reset,
        control,
    } = useForm({
        mode: "all",
        defaultValues: formData,
    });

    // Created to test error messages & input values
    const onSubmit: SubmitHandler<TYPE> = async (data) => {
        setFormData(data);

        const nameOFPerson = removeSpacesBeforeAfter(data.givenname);
        const famName = removeSpacesBeforeAfter(data.familyname);
        const work = removeSpacesBeforeAfter(data.function);
        const mobile = isPhoneNumberAllowed(data.phone);

        if (errorMutation) {
        }
        try {
            await book({
                variables: {
                    timeslot_hash: chosenTimeSlot,
                    company: data.company.handelsnaam,
                    kvk: data.company.dossiernummer,
                    first_name: nameOFPerson,
                    last_name: famName,
                    email: data.email,
                    city: data.city,
                    phone: mobile,
                    function: work,
                    linkedinGerrie: false,
                },
                onCompleted: () => {
                    // Stop query from fetching new timeslots
                    stopPolling();
                    // Set Selecteddate to empty string
                    setSelectedDate("");
                    // Set chosendate to empty string
                    setChosenTimeSlot("");
                    // Set finalview
                    handleNext();
                    // Get meeting details
                    GetMeetingDetails({
                        variables: {
                            hash: chosenTimeSlot,
                        },
                        onCompleted: () => {
                            reset({
                                givenname: "",
                                familyname: "",
                                email: "",
                                phone: "",
                                city: "",
                                company: { handelsnaam: "", dossiernummer: "" },
                                function: "",
                            });
                            setFormData({
                                givenname: "",
                                familyname: "",
                                email: "",
                                phone: "",
                                city: "",
                                company: { handelsnaam: "", dossiernummer: "" },
                                function: "",
                            });
                        },
                    });
                },
            });
        } catch (e) {}
    };

    // Fetch al available timeslots of type "support"
    const [run, { loading: load, data: availableSlots, startPolling, stopPolling }] = useLazyQuery<
        GetSalesTimeSlotsQuery,
        GetSalesTimeSlotsQueryVariables
    >(QUERY_TIMESLOTS, {
        variables: {
            from: selectedDate,
        },
        // pollInterval: 500,
        fetchPolicy: "network-only",
    });

    /**
     * Fetch details of chosen timeslot
     */
    const [GetMeetingDetails] = useLazyQuery(GET_CHOSEN_TIMESLOT_DETAILS, {
        onCompleted: (data) => {
            if (data.meetingTimeslot) setMeetingDetails(data.meetingTimeslot);
        },
    });

    useEffect(() => {
        GetMeetingDetails({
            variables: {
                hash: chosenTimeSlot,
            },
        });
    }, [GetMeetingDetails, chosenTimeSlot]);

    // Start polling if no timeslot is chosen
    // stop polling if user has a timeslot chosen
    useEffect(() => {
        chosenTimeSlot === "" &&
        selectedDate !== "" &&
        availableSlots?.meetingTimeslots?.length !== undefined &&
        availableSlots?.meetingTimeslots?.length > 0
            ? startPolling(500)
            : stopPolling();
    }, [selectedDate, chosenTimeSlot, startPolling, stopPolling, availableSlots?.meetingTimeslots?.length]);

    useEffect(() => {
        if (moment(selectedDate).isBefore(moment())) {
            return;
        }
        run({
            variables: {
                from: selectedDate,
            },
        });
    }, [run, selectedDate]);

    /**
     * Function to handle chosen date for query
     * @param date clicked date in calendar
     */
    const handleDateChange = (date: any) => {
        const chosenDate = moment(date).format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");
        if (chosenDate === today) {
            setSelectedDate(moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setSelectedDate(moment(chosenDate).format("YYYY-MM-DD HH:mm:ss"));
        }
        // if its the first view => trigger next view
        if (activeStep === 0) handleNext();
    };

    // const getSteps = () => {
    //     return ["one", "two", "three"];
    // };

    // Get step titles
    // const steps = getSteps();

    // Get total amount of steps
    // const totalSteps = () => {
    //     return steps.length;
    // };

    // Get last step
    // const isLastStep = () => {
    //     return activeStep === totalSteps() - 1;
    // };

    // Handler to go to next step
    // Check if next step is last step. If so, save mutation and close modal
    const handleNext = () => {
        // If active step is 0, set query to single string with OR operator
        if (activeStep === 0) {
            // timeslot view with calendar
        }

        if (activeStep === 1) {
            // Fetched timeslots and userform
        }

        if (activeStep === 2) {
            // Feedback to user with booking info
        }

        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    };

    // Handler to go to next step
    // Check if next step is last step. If so, save mutation and close modal
    const handlePrev = () => {
        setChosenTimeSlot("");
        setSelectedDate("");
        setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
    };

    // Handler to go to previous step
    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };
    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                /**
                 * First step: calendar
                 * Choose a date
                 */
                return (
                    <DemoPlannerStepOne
                        onDateChange={handleDateChange}
                        selectedDate={selectedDate}
                        timeslotarray={timeslotarray.data?.meetingTimeslotDays}
                    />
                );
            case 1:
                /**
                 * show results based on chosen date (step 1)
                 * form for user to fill in to book a timestamp
                 */
                return (
                    <form onSubmit={submit(onSubmit)} style={{ width: "100%" }}>
                        <DemoPlannerStepTwo
                            selectedDate={selectedDate}
                            setSelectedDate={handleDateChange}
                            onPrevClick={handlePrev}
                            availableSlots={availableSlots?.meetingTimeslots}
                            chosenTimeSlot={chosenTimeSlot}
                            setChosenTimeSlot={setChosenTimeSlot}
                            loadingForm={load || loadingMutation}
                            errors={errors}
                            control={control}
                            setChosenOrg={setChosenOrg}
                            chosenOrg={chosenOrg}
                            isValid={isValid}
                            setSearchterm={setSearchterm}
                            searchterm={searchterm}
                            timeslotarray={timeslotarray.data?.meetingTimeslotDays}
                        />
                    </form>
                );

            case 2:
                /**
                 * Final view
                 * feedback for user with appointment details
                 */
                return <DemoPlannerDetailView meetingDetails={meetingDetails} />;

            default:
                return <div />;
        }
    };

    return (
        <div className="demo-card-container">
            <Typography className="header-title" id="demo-request">
                Vraag een <span className="boldHeader">demo</span> aan
            </Typography>

            <Card className="demo-card" raised={raised}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>{getStepContent(activeStep)}</div>
            </Card>
        </div>
    );
};

export default DemoPlannerCard;

//? get all users in organization
export const QUERY_TIMESLOTS_DATES = gql`
    query meetingTimeslotDays {
        meetingTimeslotDays(type: SALES)
    }
`;
