import { createContext, useContext } from "react";

export type ShowLogoType = {
    showLogo: boolean;
    setShowLogo: (showLogo: boolean) => void;
    showButtons: boolean;
    setShowButtons: (showButtons: boolean) => void;
};

export const ShowLogoContext = createContext<ShowLogoType>({
    showLogo: false,
    setShowLogo: (showLogo) => console.warn("no theme provider"),
    showButtons: false,
    setShowButtons: (showLogo) => console.warn("no theme provider"),
});
export const useLogoSetter = () => useContext(ShowLogoContext);
