import React, { useEffect, useState } from "react";

import { Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import DemoPlannerDetailView from "../../components/demoplannercard/DemoPlannerDetailView";
import DemoPlannerStepTwo from "../../components/demoplannercard/DemoPlannerStepTwo";
import { GET_CHOSEN_TIMESLOT_DETAILS, QUERY_TIMESLOTS_SUP, SAVE_CHOSEN_TIMESLOT } from "../../queryDefinitions";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
    GetSalesTimeSlotsSupportQuery,
    GetSalesTimeSlotsSupportQueryVariables,
    MeetingTimeslot,
    MeetingTimeslotDaysSupportQuery,
    Save_Chosen_TimeslotMutation,
    Save_Chosen_TimeslotMutationVariables,
} from "../../__generated__/graphql";
import { TYPE, useTimeslotDrawer } from "../landing_page/salesTimeslot/TimeSlotProvider";
import { SubmitHandler, useForm } from "react-hook-form";
import moment from "moment";
import { isValidPhoneNumber } from "react-phone-number-input";

interface Props {
    a?: number;
}

const BookingRoot: React.FC<Props> = (props) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const tablet = useMediaQuery(theme.breakpoints.up("lg"));

    const {
        formData,
        setFormData,
        searchterm,
        setSearchterm,
        chosenOrg,
        setChosenOrg,
        chosenTimeSlot,
        setChosenTimeSlot,
        selectedDate,
        setSelectedDate,
        activeStep,
        setActiveStep,
    } = useTimeslotDrawer();

    const [meetingDetails, setMeetingDetails] = useState<MeetingTimeslot | null>(null);

    const timeslotarray = useQuery<MeetingTimeslotDaysSupportQuery>(QUERY_TIMESLOTS_DATES_SUP);

    /**
     * Book timeslot
     */
    const [book, { error: errorMutation, loading: loadingMutation }] = useMutation<
        Save_Chosen_TimeslotMutation,
        Save_Chosen_TimeslotMutationVariables
    >(SAVE_CHOSEN_TIMESLOT);

    const {
        handleSubmit: submit,
        formState: { errors, isValid },
        reset,
        control,
    } = useForm({
        mode: "all",
        defaultValues: formData,
    });

    // Created to test error messages & input values
    const onSubmit: SubmitHandler<TYPE> = async (data) => {
        setFormData(data);

        const nameOFPerson = removeSpacesBeforeAfter(data.givenname);
        const famName = removeSpacesBeforeAfter(data.familyname);
        const work = removeSpacesBeforeAfter(data.function);
        const mobile = isPhoneNumberAllowed(data.phone);

        if (errorMutation) {
        }
        try {
            await book({
                variables: {
                    timeslot_hash: chosenTimeSlot,
                    company: data.company.handelsnaam,
                    kvk: data.company.dossiernummer,
                    first_name: nameOFPerson,
                    last_name: famName,
                    email: data.email,
                    city: data.city,
                    phone: mobile,
                    function: work,
                    linkedinGerrie: true,
                },
                onCompleted: () => {
                    // Stop query from fetching new timeslots
                    stopPolling();
                    // Set Selecteddate to empty string
                    setSelectedDate("");
                    // Set chosendate to empty string
                    setChosenTimeSlot("");
                    // Set finalview
                    handleNext();
                    // Get meeting details
                    GetMeetingDetails({
                        variables: {
                            hash: chosenTimeSlot,
                        },
                        onCompleted: () => {
                            reset({
                                givenname: "",
                                familyname: "",
                                email: "",
                                phone: "",
                                city: "",
                                company: { handelsnaam: "", dossiernummer: "" },
                                function: "",
                            });
                            setFormData({
                                givenname: "",
                                familyname: "",
                                email: "",
                                phone: "",
                                city: "",
                                company: { handelsnaam: "", dossiernummer: "" },
                                function: "",
                            });
                        },
                    });
                },
            });
        } catch (e) {}
    };

    // Fetch al available timeslots of type "support"
    const [run, { loading: load, data: availableSlots, startPolling, stopPolling }] = useLazyQuery<
        GetSalesTimeSlotsSupportQuery,
        GetSalesTimeSlotsSupportQueryVariables
    >(QUERY_TIMESLOTS_SUP, {
        variables: {
            from: moment().format("YYYY-MM-DD"),
        },
        // pollInterval: 500,
        fetchPolicy: "network-only",
    });

    /**
     * Fetch details of chosen timeslot
     */
    const [GetMeetingDetails] = useLazyQuery(GET_CHOSEN_TIMESLOT_DETAILS, {
        onCompleted: (data) => {
            if (data.meetingTimeslot) setMeetingDetails(data.meetingTimeslot);
        },
    });

    useEffect(() => {
        GetMeetingDetails({
            variables: {
                hash: chosenTimeSlot,
            },
        });
    }, [GetMeetingDetails, chosenTimeSlot]);

    // Start polling if no timeslot is chosen
    // stop polling if user has a timeslot chosen
    useEffect(() => {
        chosenTimeSlot === "" &&
        selectedDate !== "" &&
        availableSlots?.meetingTimeslots?.length !== undefined &&
        availableSlots?.meetingTimeslots?.length > 0
            ? startPolling(500)
            : stopPolling();
    }, [selectedDate, chosenTimeSlot, startPolling, stopPolling, availableSlots?.meetingTimeslots?.length]);

    useEffect(() => {
        if (selectedDate === "") {
            run({
                variables: {
                    from: moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss"),
                },
            });
            setSelectedDate(moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss"));
        } else if (moment(selectedDate).isBefore(moment())) {
            return;
        } else {
            run({
                variables: {
                    from: selectedDate,
                },
            });
        }
    }, [run, selectedDate, setSelectedDate]);

    /**
     * Function to handle chosen date for query
     * @param date clicked date in calendar
     */
    const handleDateChange = (date: any) => {
        const chosenDate = moment(date).format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");
        if (chosenDate === today) {
            setSelectedDate(moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setSelectedDate(moment(chosenDate).format("YYYY-MM-DD HH:mm:ss"));
        }
        // if its the first view => trigger next view
        // if (activeStep === 0) handleNext();
    };

    // Handler to go to next step
    // Check if next step is last step. If so, save mutation and close modal
    const handleNext = () => {
        // If active step is 0, set query to single string with OR operator
        if (activeStep === 0) {
            // timeslot view with calendar
        }

        if (activeStep === 1) {
            // Fetched timeslots and userform
        }

        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    };

    // Handler to go to next step
    // Check if next step is last step. If so, save mutation and close modal
    const handlePrev = () => {
        setChosenTimeSlot("");
        setSelectedDate("");
        setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
    };

    // Handler to go to previous step
    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };
    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                /**
                 * form for user to fill in to book a timestamp
                 */
                return (
                    <form onSubmit={submit(onSubmit)} style={{ width: "100%" }}>
                        <DemoPlannerStepTwo
                            selectedDate={selectedDate}
                            setSelectedDate={handleDateChange}
                            onPrevClick={handlePrev}
                            availableSlots={availableSlots?.meetingTimeslots}
                            chosenTimeSlot={chosenTimeSlot}
                            setChosenTimeSlot={setChosenTimeSlot}
                            loadingForm={load || loadingMutation}
                            errors={errors}
                            control={control}
                            setChosenOrg={setChosenOrg}
                            chosenOrg={chosenOrg}
                            isValid={isValid}
                            setSearchterm={setSearchterm}
                            searchterm={searchterm}
                            timeslotarray={timeslotarray.data?.meetingTimeslotDays}
                        />
                    </form>
                );

            case 1:
                /**
                 * Final view
                 * feedback for user with appointment details
                 */
                return <DemoPlannerDetailView meetingDetails={meetingDetails} />;

            default:
                return <div />;
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <div style={{ display: "flex", width: tablet ? "70%" : noMobile ? "90%" : "100%", height: noMobile ? `calc(100vh - 192px)` : undefined }}>
                <div className="demo-card-container">
                    <Typography className="header-title" id="demo-request">
                        Vraag een <span className="boldHeader">demo</span> aan met <span className="boldHeader">Gerrie</span>
                    </Typography>

                    <Card className="demo-card" raised={true} sx={{ overflow: "auto" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>{getStepContent(activeStep)}</div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default BookingRoot;

const disallowedPhones = [
    "000000",
    "111111",
    "222222",
    "333333",
    "444444",
    "555555",
    "666666",
    "777777",
    "888888",
    "999999",
    "123456",
    "234567",
    "345678",
    "456789",
    "012345",
    "567890",
    "098765",
    "987654",
    "876543",
    "765432",
    "654321",
    "543210",
    "010101",
    "101010",
];

export const removeSpacesBeforeAfter = (str: string): string => {
    const trimmedStr = str.trim();
    // Remove all characters except letters and hyphens
    return trimmedStr.replace(/\s+/g, " ").replace(/[^a-zA-ZÀ-ÿ-'’éá\s]/g, "");
};

export const isPhoneNumberAllowed = (phoneNumber: string): string => {
    for (const pattern of disallowedPhones) {
        if (phoneNumber.includes(pattern)) {
            return "";
        }
    }

    return isValidPhoneNumber(phoneNumber) ? phoneNumber : "";
};

//? get all users in organization
export const QUERY_TIMESLOTS_DATES_SUP = gql`
    query meetingTimeslotDaysSupport {
        meetingTimeslotDays(type: SUPPORT)
    }
`;
