import { gql } from "@apollo/client";

// query to fetch timeslots
export const QUERY_TIMESLOTS = gql`
    query GetSalesTimeSlots($from: DateTime) {
        meetingTimeslots(type: SALES, from: $from) {
            hash
            start
            end
            duration
            type
        }
    }
`;

// query to fetch timeslots
export const QUERY_TIMESLOTS_SUP = gql`
    query GetSalesTimeSlotsSupport($from: DateTime) {
        meetingTimeslots(type: SUPPORT, from: $from) {
            hash
            start
            end
            duration
            type
        }
    }
`;

export const GET_CHOSEN_TIMESLOT_DETAILS = gql`
    query Get_chosen_timeslot_details($hash: String!) {
        meetingTimeslot(hash: $hash) {
            hash
            start
            end
            duration
            type
        }
    }
`;

export const SAVE_CHOSEN_TIMESLOT = gql`
    mutation Save_chosen_timeslot(
        $timeslot_hash: ID!
        $company: String!
        $kvk: String!
        $first_name: String!
        $last_name: String!
        $email: String!
        $city: String
        $phone: String
        $function: String
        $linkedinGerrie: Boolean
    ) {
        reserveSalesTimeslot(
            timeslot_hash: $timeslot_hash
            company: $company
            city: $city
            kvk: $kvk
            first_name: $first_name
            last_name: $last_name
            email: $email
            phone: $phone
            function: $function
            linkedinGerrie: $linkedinGerrie
        ) {
            start
            end
            duration
            type
            hash
        }
    }
`;

export const CANCEL_CHOSEN_TIMESLOT = gql`
    mutation cancelSalesMeeting($timeslot_hash: ID!, $email: String!) {
        cancelSalesMeeting(timeslot_hash: $timeslot_hash, email: $email) {
            start
            end
            duration
            type
            hash
        }
    }
`;
