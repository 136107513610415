import React from "react";
import { Grid } from "@mui/material";
import UspCard from "./UspCard";
import { Description, TrendingUp, Domain, People } from "@mui/icons-material";

interface Props {
    a?: number;
}

const USP: React.FC<Props> = (props) => {
    return (
        <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={6} md={3} lg={2}>
                <UspCard
                    count={178047}
                    text="Inschrijvingen"
                    icon={<People fontSize="medium" sx={{ color: (theme) => theme.colorPalette.pink.light }} />}
                />
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
                <UspCard
                    count={369298}
                    text="Aanbestedingen"
                    icon={<Description fontSize="medium" sx={{ color: (theme) => theme.colorPalette.blue.light }} />}
                />
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
                <UspCard
                    count={3792}
                    text="Kansen"
                    icon={<TrendingUp fontSize="medium" sx={{ color: (theme) => theme.colorPalette.green.light }} />}
                />
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
                <UspCard
                    count={2676}
                    text={`Aanbestedende diensten`}
                    icon={<Domain fontSize="medium" sx={{ color: (theme) => theme.colorPalette.yellow.light }} />}
                />
            </Grid>
        </Grid>
    );
};

export default USP;
