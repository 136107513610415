import React, { useRef, useLayoutEffect } from "react";
import "./HorizontalTimeline.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

gsap.registerPlugin(ScrollTrigger);

interface Props {
    a?: number;
}

const HorizontalTimeline: React.FC<Props> = (props) => {
    const timelinebox = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const sections = self.selector!(".timeline-section");

            const scrollTween = gsap.to(sections, {
                xPercent: -100 * (sections.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: ".timeline-container",
                    pin: true,
                    scrub: 1,
                    start: noMobile ? "-20% top" : "-50% top",
                    end: "+=3000",
                },
            });

            // whizz around the sections
            sections.forEach((section: any) => {
                // grab the scoped text
                const text = section.querySelectorAll(".anim");

                // bump out if there's no items to animate
                if (text.length === 0) return;

                // do a little stagger
                gsap.from(text, {
                    y: -130,
                    opacity: 0,
                    duration: 1,
                    ease: "expo",
                    stagger: 0.1,
                    scrollTrigger: {
                        trigger: section,
                        containerAnimation: scrollTween,
                        start: "left 70%",
                    },
                });
            });
        }, timelinebox);

        return () => ctx.revert();
    }, [noMobile]);

    // array of cards
    const amountofcards = [
        {
            id: 1,
            year: 2010,
            title: "And Lift Off!",
            text: "In mei 2010 lanceerden we de allereerste versie van TenderGuide. Het begin van onze missie om aanbestedingen dichterbij te brengen.",
        },
        {
            id: 2,
            year: 2016,
            title: "Release v3",
            text: "In juli 2016 werd v3 gelanceerd. Hierin introduceerde we de kansenmodule met toekomstige investeringen van overheden. Ook werd onze huisstijl vernieuwd. De helpende hand blijft centraal staan.",
        },
        {
            id: 3,
            year: 2020,
            title: "2e Lustrum",
            text: "TenderGuide bestaat 10 jaar. Altijd voor en door mensen met tender ervaring. Alles verwerkt onder de motorkap en in het bodywork.",
        },
        { id: 4, year: 2021, title: "5K", text: "Met trots werd user 5.000 onboard. Steeds meer teams weten ons te vinden. Hoe gaaf is dat." },
        {
            id: 5,
            year: 2022,
            title: "Big data",
            text: "We beschikken over vele terabytes aan tenderinformatie. Op het platform zijn meer dan 10 miljoen aanbestedingsdocumenten doorzoekbaar.",
        },
        {
            id: 6,
            year: 2023,
            title: "Relaunch v4",
            text: "Team TenderGuide lanceert een volledig redesign. Een platform dat is gebouwd voor een nieuwe manier van werken. Alles volledig getransformeerd. Sneller en beter dan ooit tevoren. And there is more to come…",
        },
        // {
        //     id: 7,
        //     year: "Future",
        //     title: "And there is more to come…",
        //     text: "",
        // },
    ];

    return (
        <div className="wrapper" ref={timelinebox}>
            <div className="timeline-container scrollx">
                {amountofcards.map((card) => {
                    return (
                        <section key={card.id} className={`timeline-section sec${card.id} pin`}>
                            {card.id === 1 ? <span className="year">{card.year}</span> : <span className="year anim">{card.year}</span>}
                            {card.id === 1 ? <h1>{card.title}</h1> : <h1 className="anim">{card.title}</h1>}

                            <div className={card.id === 1 ? "col" : "col anim"}>
                                <p>{card.text}</p>
                            </div>
                        </section>
                    );
                })}
            </div>
        </div>
    );
};

export default HorizontalTimeline;
