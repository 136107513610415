import { Box, Button, IconButton, Slide, Toolbar } from "@mui/material";
import React from "react";
import { useTimeslotDrawer } from "../routes/landing_page/salesTimeslot/TimeSlotProvider";
import LoginIcon from "@mui/icons-material/Login";
import { ReactComponent as LogoTG } from "../assets/tenderguide_logo.svg";
import { useNavigate } from "react-router-dom";

interface Props {
    showLogo: boolean;
    showButtons: boolean;
}

const HeaderItems: React.FC<Props> = ({ showLogo, showButtons }) => {
    const navigate = useNavigate();
    /**
     * function to open timeslot modal
     */
    const { setOpenTimeslotDrawer } = useTimeslotDrawer();
    return (
        <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "flex", marginLeft: 64 } }}>
                {showLogo && <LogoTG width="200" height="200" style={{ height: "60px", cursor: "pointer" }} onClick={() => navigate("/")} />}
            </Box>
            {!showButtons ? (
                <Slide direction="left" in={!showButtons} style={{ transformOrigin: "0 0 0" }} {...(!showButtons ? { timeout: 250 } : {})}>
                    <Box sx={{ flexGrow: 0, xs: "flex", md: "none" }}>
                        <Button startIcon={<LoginIcon fontSize="small" />} style={{ marginRight: "16px" }} href="https://tool.tender.guide">
                            Login
                        </Button>
                    </Box>
                </Slide>
            ) : (
                <Box sx={{ flexGrow: 0, xs: "flex", md: "none" }}>
                    <Slide direction="left" in={showButtons} {...(showButtons ? { timeout: 250 } : {})}>
                        <Button variant="contained" color="primary" sx={{ marginRight: 6 }} onClick={() => setOpenTimeslotDrawer(true)}>
                            Plan een demo
                        </Button>
                    </Slide>
                    {/* Conditionally applies the timeout prop to change the entry speed. */}
                    <Slide direction="left" in={showButtons} style={{ transformOrigin: "0 0 0" }} {...(showButtons ? { timeout: 500 } : {})}>
                        <IconButton color="primary" style={{ marginRight: "16px" }} href="https://tool.tender.guide">
                            <LoginIcon fontSize="small" />
                        </IconButton>
                    </Slide>
                </Box>
            )}
        </Toolbar>
    );
};

export default HeaderItems;
