import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { TextField, Autocomplete, CircularProgress, useTheme, useMediaQuery } from "@mui/material";
import { Query, SearchFunction } from "../../../utils/kvkSearch";
import { debounce } from "lodash";
import _ from "lodash";

interface Props {
    errors: any;
    control: any;
    city: string;
    searchFunction: SearchFunction;
    setChosenOrg(chosenOrg: any): void;
    searchterm: string;
    setSearchterm(searchTerm: string): void;
}

const LivekvkSearchBox: React.FC<Props> = ({ errors, control, city, searchFunction, setChosenOrg, searchterm, setSearchterm }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const [results, setResults] = useState<any[]>([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    // We use a debounced search function that only calls the server when the user stops typing for 400 miliseconds
    const searchFunctionDebounced = useRef(
        debounce(async (text: Query) => {
            // We only search for results when the search string is greater then 1 character
            if (text) {
                try {
                    setLoading(true);
                    const response = await searchFunction({ query: text.query, city: text.city });
                    // filter results on type "Hoofdvestiging" & actief = "1"
                    const filteredResponseHV = response.filter((res) => res.type === "Hoofdvestiging").filter((res) => res.actief === "1");

                    // filter results on type "Rechtspersoon" & actief = "1"
                    const filteredResponseRP = response.filter((res) => res.subType === "Rechtspersoon").filter((res) => res.actief === "1");

                    // first show "hoofdvestiging" if not -> show "rechtspersoon" otherwise show response where actief = "1"
                    const dataToShow =
                        filteredResponseHV.length > 0
                            ? filteredResponseHV
                            : filteredResponseRP.length > 0
                            ? filteredResponseRP
                            : response.filter((res) => res.actief === "1");

                    // create unique array of data based. "handelsnaam" has to be the unique value
                    const dts = _.uniqBy(dataToShow, function (e) {
                        return e.handelsnaam;
                    });

                    // Set state to unique array
                    setResults(dts);
                } catch (e) {
                    console.error("Error loading search results", e);
                } finally {
                    setLoading(false);
                    setOpen(true);
                }
            } else {
                setResults([]);
            }
        }, 400)
    ).current;

    useEffect(() => {
        const search = city.length > 0 && searchterm.length > 0 ? `${searchterm} ${city}` : searchterm;
        if (search) searchFunctionDebounced({ query: searchterm, city: city });
    }, [city, searchFunctionDebounced, searchterm]);

    return (
        <Controller
            control={control}
            name="company"
            rules={{
                required: "Organisatie is verplicht",
            }}
            render={({ field: { onChange, value, onBlur } }) => (
                <Autocomplete
                    freeSolo
                    open={open}
                    onBlur={() => {
                        onBlur();
                    }}
                    // open dropdown if city is over 0 characters, search is over 2 and results are available
                    onOpen={() => {
                        if (city.length > 0 && results.length > 0 && searchterm.length > 2) {
                            setOpen(true);
                        }
                    }}
                    // close dropdown
                    onClose={() => {
                        setOpen(false);
                    }}
                    disableClearable
                    sx={{ marginBottom: noMobile ? "8px" : 0, flex: 1, marginLeft: noMobile ? "4px" : 0 }}
                    // list of found companies
                    options={results}
                    // typed input
                    onInputChange={(event, newInputValue) => {
                        // Remove chosenOrg details if user changes input
                        // added to avoid mismatch in kvk data and given company name
                        setChosenOrg(undefined);
                        // set 'handelsnaam' to user input and set 'dossiernummer' to null
                        onChange({ handelsnaam: newInputValue, dossiernummer: "null" });
                    }}
                    // selected input
                    onChange={(_event, values, reason) => {
                        if (reason === "clear") {
                            setSearchterm("");
                        } else {
                            // Set chosenOrg to clicked dropdownitem
                            onChange({ handelsnaam: values.handelsnaam, dossiernummer: values.dossiernummer });
                        }

                        setChosenOrg(values);
                        setSearchterm("");
                    }}
                    loading={loading}
                    value={value}
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => (option ? `${option.handelsnaam}` : "")}
                    renderOption={(props, option) => <li {...props}>{option.handelsnaam}</li>}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            onChange={(e) => {
                                setSearchterm(e.target.value);
                                if (e.target.value.length === 0) {
                                    setOpen(false);
                                }
                            }}
                            error={Boolean(errors.company)}
                            helperText={Boolean(errors.company) ? errors.company.message : " "}
                            label="Organisatie"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            )}
        />
    );
};

export default LivekvkSearchBox;
