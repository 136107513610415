import React from "react";
import { Box } from "@mui/material";

interface Props {
    /**
     * Customize margin for divider
     * default: 32px 0px
     */
    margin?: string;
}

const SectionDivider: React.FC<Props> = ({ margin }) => {
    return <Box sx={{ width: "100vw", margin: margin ? margin : "32px 0px" }} />;
};

export default SectionDivider;
