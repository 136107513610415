import React from "react";
import { Card, Box, CardContent, Typography, useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import CountUp from "react-countup";

interface Props {
    count: number;
    text: string;
    icon: React.ReactNode;
}

const CardContentNoPadding = styled(CardContent)(`
    min-height: 90px;
    padding: 16px;
    &:last-child {
        padding-bottom: 16px;
    }
`);

const UspCard: React.FC<Props> = ({ count, text, icon }) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Card raised sx={{ display: "flex", borderRadius: "16px", justifyContent: "center", position: "relative" }}>
            {/* Rightcorner with icon */}
            <Box
                sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    backgroundColor: (theme) => theme.colorPalette.pastel.main + "14",
                    borderBottomLeftRadius: "50px 50px",
                    padding: "16px 16px 20px 20px",
                }}
            >
                {icon}
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%" }}>
                {/* Counter  */}
                <CardContentNoPadding>
                    <Typography
                        sx={{ fontSize: !noMobile ? "1.2rem !important" : "2.22rem", color: (theme) => theme.colorPalette.blue.light }}
                        textAlign="left"
                    >
                        {/*
                         * seperator is now whitespace -> can be everyhting
                         */}
                        <CountUp separator=" " end={count} duration={1.1} />
                    </Typography>
                    {/* name */}
                    <Typography
                        variant="h5"
                        textAlign="left"
                        sx={{ fontWeight: "bold" }}
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                </CardContentNoPadding>
            </Box>
        </Card>
    );
};

export default UspCard;
