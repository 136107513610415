import React, { useLayoutEffect, useRef } from "react";
import { Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./Hero.scss";
// import dashboardImg from "../../assets/images/dashboard.png";
import ReactTypingEffect from "react-typing-effect";
import UniqueSellingPoints from "../../components/UniqueSellingPoints";
import dashboardImg from "../../assets/images/dashboard.svg";
import { ReactComponent as LogoTG } from "../../assets/tenderguide_logo.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTimeslotDrawer } from "./salesTimeslot/TimeSlotProvider";
gsap.registerPlugin(ScrollTrigger);

interface Props {
    setShowLogo(showLogo: boolean): void;
}

const Hero: React.FC<Props> = ({ setShowLogo }) => {
    const { setOpenTimeslotDrawer } = useTimeslotDrawer();
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("lg"));
    const laptop = useMediaQuery(theme.breakpoints.up("xl"));
    const hero = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            // use scoped selectors
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: ".hero",
                    start: "top top",
                    end: "+=64",
                    scrub: true,
                    pin: true,
                    toggleActions: "play reverse play reverse",
                },
            });
            tl.to(".logo", {
                xPercent: 0,
                yPercent: 0,
                x: 0,
                y: 0,
                scale: 1,
                opacity: 0,
                delay: 0,
                onComplete: () => setShowLogo(true),
                onReverseComplete: () => setShowLogo(false),
            });
        }, hero);

        return () => ctx.revert();
    }, [setShowLogo]);

    return (
        <React.Fragment>
            <Grid
                className="hero"
                ref={hero}
                container
                sx={{
                    height: noMobile ? "calc(100vh - 64px)" : "100%",
                    position: "relative",
                    padding: noMobile ? "0px 64px" : 0,
                    marginTop: noMobile ? 0 : "32px",
                }}
            >
                {/* Show logo if device is not a mobile */}
                {laptop ? (
                    <LogoTG
                        width="200"
                        height="200"
                        className="logo"
                        style={{
                            maxHeight: "60px",
                            opacity: 1,
                            translate: "none",
                            rotate: "none",
                            scale: "none",
                            transform: `translate(280px, 64px) scale(3, 3)`,
                            mixBlendMode: "multiply",
                        }}
                    />
                ) : noMobile ? (
                    <LogoTG
                        width="200"
                        height="200"
                        className="logo"
                        style={{
                            maxHeight: "60px",
                            opacity: 1,
                            translate: "none",
                            rotate: "none",
                            scale: "none",
                            transform: `translate(200px, 32px) scale(2.5, 2.5)`,
                            mixBlendMode: "multiply",
                        }}
                    />
                ) : null}
                <Grid item container style={{ zIndex: 5 }}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={5}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "center",
                                flexDirection: "column",
                                width: noMobile ? "70%" : "100%",
                                padding: noMobile ? 0 : "0px 32px",
                            }}
                        >
                            <Typography
                                variant="h1"
                                style={{
                                    fontSize: noMobile ? "2.5em" : undefined,
                                    fontWeight: "600",
                                    textAlign: "start",
                                    width: "100%",
                                }}
                            >
                                Eén platform.
                            </Typography>
                            <Typography
                                variant="h1"
                                style={{
                                    fontSize: noMobile ? "2.8em" : undefined,
                                    fontWeight: "600",
                                    textAlign: "start",
                                    width: "100%",
                                    marginBottom: "32px",
                                }}
                            >
                                <ReactTypingEffect
                                    speed={200}
                                    typingDelay={500}
                                    eraseDelay={500}
                                    eraseSpeed={100}
                                    className="typeEffect"
                                    text={["Geen gedoe.", "Aanbestedingen.", "Marktpartijen.", "Kansen.", "Aanbestedende diensten."]}
                                    displayTextRenderer={(text, i) => {
                                        return <span className={`typeEffect n${i}`}>{text}</span>;
                                    }}
                                />
                            </Typography>
                            <Typography variant="h2" textAlign="left">
                                {/* <Typography align="left" variant="body1"> */}
                                Ontdek TenderGuide en boost de kracht van jouw sales- en tenderteams
                            </Typography>
                            <div style={{ marginTop: "32px", width: "100%" }}>
                                <Button
                                    variant="contained"
                                    style={{ marginRight: "16px" }}
                                    onClick={() => {
                                        setOpenTimeslotDrawer(true);
                                    }}
                                >
                                    Plan een demo
                                </Button>
                                {/* {noMobile && <Button style={{ marginRight: 16 }}>Ontdek meer</Button>} */}
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={7}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: noMobile ? 0 : "32px",
                        }}
                    >
                        {laptop ? (
                            <div style={{ borderRadius: "33% 67% 30% 70% / 66% 41% 59% 34%", backgroundColor: "#5f4aea14" }}>
                                <img alt="img" src={dashboardImg} style={{ height: "auto", minWidth: "1000px" }} />
                            </div>
                        ) : noMobile ? (
                            <div
                                style={{
                                    width: "100%",
                                    borderRadius: "33% 67% 30% 70% / 66% 41% 59% 34%",
                                    backgroundColor: "#5f4aea14",
                                    margin: "48px 0px",
                                }}
                            >
                                <img alt="img" src={dashboardImg} style={{ height: "auto", width: "100%" }} />{" "}
                            </div>
                        ) : (
                            <div
                                style={{
                                    width: "85%",
                                    borderRadius: "33% 67% 30% 70% / 66% 41% 59% 34%",
                                    backgroundColor: "#5f4aea14",
                                    margin: "48px 0px",
                                }}
                            >
                                <img alt="img" src={dashboardImg} style={{ height: "auto", width: "100%" }} />{" "}
                            </div>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        zIndex: 10,
                        alignSelf: "flex-end",
                        paddingBottom: "16px",
                        paddingTop: !noMobile ? "32px" : 0,
                        paddingLeft: !noMobile ? "32px" : 0,
                        paddingRight: !noMobile ? "32px" : 0,
                    }}
                >
                    <UniqueSellingPoints />
                </Grid>
                {noMobile && (
                    <div
                        style={{
                            position: "absolute",
                            bottom: "0px",
                            left: "-250px",
                            height: "400px",
                            width: "600px",
                        }}
                    >
                        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill="#5f4aea14"
                                d="M51,-53.3C62.2,-39.7,64.8,-19.9,64.9,0.1C65.1,20.2,62.8,40.3,51.6,51.2C40.3,62.1,20.2,63.7,-1.4,65C-22.9,66.4,-45.8,67.6,-53.1,56.7C-60.3,45.8,-51.9,22.9,-50.7,1.3C-49.4,-20.3,-55.2,-40.7,-47.9,-54.2C-40.7,-67.7,-20.3,-74.4,-0.2,-74.2C19.9,-74,39.7,-66.8,51,-53.3Z"
                                transform="translate(100 100)"
                            />
                        </svg>
                    </div>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default Hero;
