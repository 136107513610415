import React, { useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";

import { ThemeProvider } from "@mui/material/styles";
import { materialTheme } from "./assets/styles";
import MainLayout from "./components/MainLayout";
import secureLocalStorage from "react-secure-storage";
import { client } from "./__generated__/gqlClient";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/landing_page/Root";

import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/nl";
import ErrorPage from "./routes/error_page/ErrorPage";
import TimeSlotDetails from "./routes/timeslot_detail/TimeSlotDetails";
import { ShowLogoContext } from "./context_providers/showlogoProvider";
import BookingRoot from "./routes/timeslotbooker/BookingRoot";

function App() {
    const [showLogo, setShowLogo] = useState<boolean>(false);
    const [showButtons, setShowButtons] = useState<boolean>(false);

    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <MainLayout showLogo={showLogo} showButtons={showButtons}>
                    <Root />
                </MainLayout>
            ),
            errorElement: (
                <MainLayout showLogo={true} showButtons={false}>
                    <ErrorPage />
                </MainLayout>
            ),
        },
        {
            path: "/demo-request/9bb9a59c-38a3-4148-acbd-b1f149ad6caad",
            element: (
                <MainLayout showLogo={true} showButtons={false}>
                    <BookingRoot />
                </MainLayout>
            ),
            errorElement: (
                <MainLayout showLogo={true} showButtons={false}>
                    <ErrorPage />
                </MainLayout>
            ),
        },
        {
            path: "/timeslot/:id",
            element: (
                <MainLayout showLogo={true} showButtons={false}>
                    <TimeSlotDetails />
                </MainLayout>
            ),
            errorElement: (
                <MainLayout showLogo={true} showButtons={false}>
                    <ErrorPage />
                </MainLayout>
            ),
        },
    ]);

    useEffect(() => {
        // check if localstorage key "version" exist
        const version = secureLocalStorage.getItem("version");
        if (version !== "1.0.0.9") {
            // if not, create it and set it to 1.0.0.9
            secureLocalStorage.setItem("version", "1.0.0.9");
        }
    }, []);

    useEffect(() => {
        if (window.location.href.includes("demo-request")) {
            const scrollingElement = document.scrollingElement || document.body;
            scrollingElement.scrollTop = scrollingElement.scrollHeight;
        }
    }, []);

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={materialTheme}>
                <ShowLogoContext.Provider value={{ showLogo, setShowLogo, setShowButtons, showButtons }}>
                    <RouterProvider router={router} />
                </ShowLogoContext.Provider>
            </ThemeProvider>
        </ApolloProvider>
    );
}

export default App;
