import React, { useState, useEffect } from "react";
import { Badge, Box, Grid, ListItem, MenuList, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker } from "@mui/x-date-pickers";
import { Schedule, Check } from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Example from "../../assets/helperImg.png";
import "./DemoPlannerStepOne.scss";
import moment from "moment";

interface Props {
    onDateChange: any;
    selectedDate: string;
    timeslotarray: any[] | null | undefined;
}

const DemoPlannerStepOne: React.FC<Props> = ({ selectedDate, onDateChange, timeslotarray }) => {
    // const disableWeekends = (date: any) => {
    //     return moment(date).day() === 0 || moment(date).day() === 6;
    // };
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const [highlightedDays, setHighlightedDays] = useState([] as any);

    /**
     * Set timeslotarray to highlighted state
     * highlighted state will be used to generate pink dots to show user on which date timeslots will be available
     */
    useEffect(() => {
        if (timeslotarray) {
            setHighlightedDays(timeslotarray);
        }
    }, [timeslotarray]);

    /**
     * Map over date array to set format to moment()
     */
    const moments = highlightedDays.map((d: any) => moment(d));

    /**
     * Get latest date in array
     * is used to set the max range of clickable dates
     */
    const maxDate = moment.max(moments);

    /**
     * @returns badge component is visible when a timeslot is available on a specific date
     */
    function ServerDay(props: PickersDayProps<any> & { highlightedDays?: string[] }) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
        const isSelected = highlightedDays.includes(moment(props.day).format("YYYY-MM-DD"));

        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={
                    isSelected ? (
                        <Box
                            sx={{
                                width: "8px",
                                height: "8px",
                                borderRadius: "4px",
                                marginRight: "4px",
                                marginTop: "4px",
                                backgroundColor: (theme) => theme.colorPalette.pink.main,
                            }}
                        />
                    ) : undefined
                }
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    return (
        <Grid container className="demo-grid">
            <Grid container item xs={12} lg={8} className="grid-left">
                <Grid className="left-left-panel" item xs={12} lg={6}>
                    <img src={Example} alt="meet" />
                </Grid>
                <Grid item xs={12} lg={6} className="left-right-panel">
                    <MenuList className="left-menu">
                        <ListItem>
                            {noMobile && <div className="no-square" />}
                            <Typography variant="h1" className="list-text-header">
                                Wat kun je verwachten?
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <div className="square">
                                <Check className="check" />
                            </div>
                            <Typography className="list-text">Een online demonstratie voor de mogelijkheden van jouw team</Typography>
                        </ListItem>
                        <ListItem>
                            <div className="square">
                                <Check className="check" />
                            </div>
                            <Typography className="list-text">Persoonlijk advies over de inzet van ons platform</Typography>
                        </ListItem>
                        <ListItem>
                            <div className="square">
                                <Check className="check" />
                            </div>
                            <Typography className="list-text">Gratis voorproefje van jouw gepersonaliseerde omgeving</Typography>
                        </ListItem>
                    </MenuList>
                </Grid>

                <Grid item xs={12} className="meeting-grid">
                    <div className="meeting-details">
                        <div className="meeting-item">
                            <div className="square">
                                <Schedule sx={{ color: (theme) => theme.colorPalette.grey.light, width: 24, height: 24 }} />
                            </div>
                            <Typography className="list-text">20-30 min.</Typography>
                        </div>
                        <div className="meeting-item">
                            <div className="square">
                                <img src="https://ssl.gstatic.com/calendar/images/conferenceproviders/logo_meet_2020q4_192px.svg" alt="Google Meet" />
                            </div>
                            <Typography className="list-text">Informatie over Google Meet ontvang je in de bevestigingsmail</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={4} className="grid-right">
                <Typography variant="h1" className="header">
                    Kies een datum
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StaticDatePicker
                        orientation="landscape"
                        // shouldDisableDate={disableWeekends}
                        disablePast
                        // minimum clickable date is today
                        minDate={moment()}
                        //  max. clickable date is last date in date array
                        maxDate={maxDate}
                        // override day component
                        slots={{
                            day: ServerDay,
                        }}
                        showDaysOutsideCurrentMonth
                        // show dayview
                        views={["day"]}
                        // chosen date in moment format
                        value={moment(selectedDate)}
                        // function to fire on date change
                        onChange={onDateChange}
                        /**
                         * Changes in GUI
                         * - Hide toolbar
                         * - Hide buttons in action bar
                         * - Push highlighted days array to dat component (array used in ServerDay)
                         */
                        slotProps={{
                            toolbar: {
                                // Customize value display
                                toolbarFormat: "YYYY",
                                // Change what is displayed given an empty value
                                toolbarPlaceholder: "??",
                                // Hide the toolbar
                                hidden: true,
                            },
                            day: {
                                highlightedDays,
                            } as any,
                            actionBar: {
                                actions: [],
                            },
                        }}
                    />
                </LocalizationProvider>
            </Grid>
        </Grid>
    );
};

export default DemoPlannerStepOne;
