import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import ReferentieCarousel from "../../components/ReferentieCarousel";
import "./TestimonialSection.scss";
interface Props {
    a?: number;
}

const TestimonialSection: React.FC<Props> = (props) => {
    const theme = useTheme();
    const noMobile = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <Grid item container sx={{ padding: noMobile ? "0px 64px" : "0px 32px" }} className="testimonialGrid">
            <Typography className="h1" variant="h1">
                Teams van klein tot groot werken met ons platform
            </Typography>

            {/*
             * Carousel
             */}
            <ReferentieCarousel />
        </Grid>
    );
};

export default TestimonialSection;
